<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :tabOptions.sync="tabOptions"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :popupParam.sync="popupParam"
          :sop.sync="sop"
          :rev.sync="rev"
          :isOld.sync="isOld"
          :contentHeight="contentHeight"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sopDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        tabpage: 'sopInfo',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'sopInfo',
      tabOptions: {
        additionalInfo: {
          tabLabelStyle: ''
        },
      },
      sop: {
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        sopNo: '',  // 안전작업 표준 번호
        sopName: '',  // 세부공정(작업) 명
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        applyRange: '',  // 적용범위
        purpose: '',  // 목적
        hazardGradeCd: null,  // 위험등급 1 ~ 5등급
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        workClassCd: null,  // 작업구분 : Set-up, PM, BM, Part교체, 기타
        workerName: '',  // 작업자
        managementDepts: '',  // 관리부서 복수
        managementDepts2: '',  // 관리부서 복수
        saftyShied: '',  // 안전보호구
        saftyCaution: '',  // 안전주의사항
        useFlag: 'Y',  // 사용여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        regUserId: '',
        chgUserId: '',
        revSelectValue: null,
        sopSteps: [], // 작업단계 목록
        revs: [], // 개정 목록
        mmEquips: [], // 설비 목록
        mmMachines: [], // 기계기구 목록
        chems: [], // 화학자재 목록
        sopSummary: [], // 요약정보
        sopStepHazards: [], // 작업단계 (w/h. 유해위험요인)
      },
      rev: {
        isRevision: false,
      },
      detailUrl: '',
      revlistUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmSopId)
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let items = [
        { name: 'sopInfo', icon: 'info', label: '작업정보', component: () => import(`${'./sopInfo.vue'}`), key: uid(), },
      ]
      if (this.isOld) {
        items = this.$_.concat(items, [
          { name: 'additionalInfo', icon: 'playlist_add', label: '부가정보', component: () => import(`${'./sopAdditionalInfo.vue'}`), key: uid(), disabled: this.rev.isRevision ? true : false },
        ])
      }
      return items;
    },
  },
  watch: {
    'rev.isRevision'() {
      this.$set(this.tabOptions['additionalInfo'], 'tabLabelStyle', this.rev.isRevision ? 'text-decoration-line:line-through;text-decoration-color:#C10015' : '')
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.sop.job.get.url;
      this.revlistUrl = selectConfig.mdm.sop.job.revList.url;
      // code setting
      if (this.popupParam.tabpage) {
        this.tab = this.popupParam.tabpage;
      }
      // list setting
      this.getDetail();
    },
    getDetail(mdmSopId) {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, (!mdmSopId ? this.popupParam.mdmSopId : mdmSopId));
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.sop, _result.data);
          this.$set(this.sop, 'revSelectValue', this.popupParam.mdmSopId)
        },);
      }
    },
    getRevList() {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        groupId: this.sop.groupId
      };
      this.$http.request((_result) => {
        this.$set(this.sop, 'revs', _result.data);
      },);
    },
  }
};
</script>